<template>
  <div class="pay-page">
    <div class="pay-content">
      <template v-if="step == 0">
        <h3 class="title">成为网站内部会员</h3>
        <div class="package-wrap">
          <div
            :key="item.pid"
            v-for="item in packages"
            class="package-item"
            :class="{ active: pid == item.pid }"
            @click="choosePackage(item.pid)"
          >
            <p class="name">{{ item.name }}</p>
            <p class="price">￥{{ item.price }}元</p>
            <p class="tip">
              平均
              <span class="num"
                >{{
                  Math.round((item.price * 100) / item.duration) / 100
                }}元 </span
              >/天
            </p>
          </div>
        </div>
        <div class="pay-wrap">
          <div class="button-wrap">
            <van-button type="primary" @click="pay">提交订单</van-button>
          </div>
        </div>
      </template>

      <template v-if="step == 1">
        <div class="qrcode-wrap">
          <h3>等待支付中...</h3>
          <img
            src="https://res.yimiaopan.com/zhi/pay_confirm.png"
            class="confirm"
            alt="支付扫码"
          />
        </div>
        <div class="button-wrap">
          <van-button @click="retry">重新选择套餐</van-button>
          <van-button type="primary" @click="confirmOrder">我已支付</van-button>
        </div>
      </template>

      <template v-if="step == 2">
        <h3>充值成功</h3>
        <div class="success-wrap">
          <lottie-player
            class="gift-animation"
            src="https://res.yimiaopan.com/pan/lottie/gift.json"
            background="transparent"
            speed="0.8"
            autoplay
          />
          <p class="tip">💐恭喜您已成为本站内部会员</p>
          <p class="em">您可以返回网站继续使用了~</p>
        </div>
      </template>
      <div class="warn-tip em">
        站点会员捐赠说明：<br />
        1.支持无理由退捐，请联系QQ：3556044645，承诺7x24小时内响应处理<br />
        2.搜索引擎结果具备动态不确定性，如介意结果不满意，请勿捐赠<br />
        3.有问题可邮箱留言：shakanamo945@gmail.com
      </div>
    </div>
  </div>
</template>

<script>
/* global WeixinJSBridge */
import { getPayPackages, createOrder, queryOrder } from "../api/pay";

export default {
  data() {
    return {
      step: 0,
      pid: "",
      packages: [],
      order_id: "",
      timer: null,
      channel: "official",
    };
  },
  async created() {
    const packages = await getPayPackages();
    this.packages = packages;
    this.pid = this.packages[0].pid;
  },
  methods: {
    choosePackage(pid) {
      this.pid = pid;
    },
    async pay() {
      const res = await createOrder({
        pid: this.pid,
        paytype: "official_jsapi",
      });
      if (res.code == 0) {
        const {
          tradeno,
          appId,
          timeStamp,
          signType,
          paySign,
          nonceStr,
          package: packageData,
        } = res.data;
        this.order_id = tradeno;
        this.$toast.loading("正在调取支付窗口");
        WeixinJSBridge.invoke("getBrandWCPayRequest", {
          appId,
          timeStamp,
          signType,
          paySign,
          nonceStr,
          package: packageData,
        });
        this.step = 1;
        this.checkOrderStatus(this.order_id);
      } else {
        this.$toast("请稍后重试");
      }
    },
    retry() {
      this.timer && clearInterval(this.timer);
      this.step = 0;
    },
    checkOrderStatus(order_id) {
      this.timer && clearInterval(this.timer);
      this.timer = setInterval(async () => {
        const res = await queryOrder({ order_id, type: "official" });
        if (res.code == 0) {
          this.$toast.success("支付成功");
          this.timer && clearInterval(this.timer);
          this.step = 2;
        }
      }, 1000);
    },
    async confirmOrder() {
      const res = await queryOrder({
        order_id: this.order_id,
        type: "official",
      });
      if (res.code == 0) {
        this.$toast.success("支付成功");
        this.step = 2;
      } else {
        this.$toast.fail("未检测到支付");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_var.scss";

.pay-page {
  padding-top: 60px;
  .website-info {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      height: 140px;
    }
    h3 {
      font-size: 24px;
      color: $theme-color;
      margin-left: 15px;
    }
  }
}
.pay-content {
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
  h3 {
    font-size: 18px;
    text-align: center;
    padding-bottom: 20px;
  }
  .content {
    padding: 0px 20px 20px;
  }
  .package-wrap {
    display: flex;
    justify-content: space-between;
    .package-item {
      width: 48%;
      padding: 20px 10px;
      border-radius: 4px;
      border: 1px solid #ddd;
      text-decoration: none;
      text-align: center;
      color: #666;
      font-size: 13px;
      transition: all ease 0.2s;
      .name {
        font-size: 18px;
        font-weight: bold;
      }
      &:hover,
      &.active {
        cursor: pointer;
        border-color: $theme-color;
        color: $theme-color;
      }
      .price {
        margin-top: 10px;
        font-size: 14px;
        font-weight: bold;
      }
      .tip {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px dashed #eee;
        .num {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
  .label {
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 10px;
  }

  .pay-wrap {
    margin-top: 25px;
    .paytype-wrap {
      display: flex;
    }
    .paytype {
      display: inline-block;
      font-size: 13px;
      width: 120px;
      height: 40px;
      border-radius: 2px;
      margin-right: 10px;
      border: 1px solid #ddd;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all ease 0.2s;
      .alipay {
        color: #1296db;
      }
      .wxpay {
        color: #45bb35;
      }
      i {
        font-size: 16px;
        margin-right: 5px;
      }
      &:hover,
      &.active {
        cursor: pointer;
        color: $theme-color;
        border-width: 2px;
        border-color: $theme-color;
      }
    }
  }

  .qrcode-wrap {
    text-align: center;
    min-height: 260px;
    .confirm {
      width: 240px;
    }
    .qrcode {
      padding: 10px;
      border: 1px solid #eee;
      background-color: #f5f5f5;
      border-radius: 4px;
    }
    .tip {
      margin-top: 5px;
      font-size: 13px;
      display: flex;
      align-items: center;
      color: #666;
      justify-content: center;
      i {
        font-size: 20px;
      }
      &.danger {
        cursor: pointer;
        &:hover {
          font-weight: bold;
        }
        i {
          font-size: 15px;
          margin-left: 4px;
        }
      }
    }
  }

  .success-wrap {
    text-align: center;
    i {
      font-size: 80px;
    }
    .gift-animation {
      height: 120px;
      width: 120px;
    }
    .tip {
      margin-top: 10px;
      font-size: 13px;
      .num {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .em {
      font-weight: bold;
      margin-top: 20px;
      font-size: 15px;
    }
  }
  .button-wrap {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .van-button {
      height: 40px;
      width: 44%;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .warn-tip {
    margin-top: 20px;
    padding: 5px;
    color: rgb(128, 82, 50);
    font-size: 12px;
    border-radius: 2px;
    background-color: #fffbe8;
    text-align: justify;
    border: 1px solid rgba(73, 33, 5, 0.2);
  }
}

@media (max-width: 700px) {
  .pay-page {
    padding-right: 15px;
    padding-left: 15px;
    h3 {
      font-size: 15px;
    }
  }
}
</style>
